import React, {} from "react";
import {
  StyledAdoptPayment,
  StyledPaymentParagraph,
} from "./style";

export default ({
  items,
}) => {

  return (
    <StyledAdoptPayment>
      { items.map((item, index) => (
        <div key={`payment-paragraph-${index}`}>
          <StyledPaymentParagraph
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      ))
      }
    </StyledAdoptPayment>
  );
};
