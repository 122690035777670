import styled from "styled-components";
import { Rem } from "../../commons/Theme";

export const StyledAdoptPayment = styled.div`
  text-align: center;
`;

export const StyledPaymentParagraph = styled.p`
    min-height: ${Rem(60)};
    font-size: ${Rem(22)};
`;
